<template>
  <div class="">
    <div class="topCenter" v-if="enterpriseinfo">
      <div
        :style="{ width: this.$oucy.pageWidth + 'px' }"
        class="topCenterBody"
        @click="$oucy.go('/ShopVip?enterpriseId=' + enterpriseinfo.id)"
      >
        <img
          :src="$oucy.ossUrl + enterpriseinfo.enterpriseAvata"
          class="merchantLogo"
          v-if="enterpriseinfo && enterpriseinfo.enterpriseAvata"
        />
        <div class="merchantText p-l-25 c-f">
          <div class="df-jc-s-b df-ai-c">
            <div class="f24 pointer">
              <div class="df">
                <el-popover width="300" trigger="hover">
                  <div class="df-ai-c m-t-5">
                    <img src="@/assets/images/product/strength_icon.png" />
                    <span class="m-l-20 m-r-10">星级</span>
                    <el-rate
                      class="m-r-30"
                      v-model="enterpriseinfo.enterpriseStar / 2"
                      disabled
                      disabled-void-color="#888888"
                      void-color="#888888"
                      score-template="{value}"
                    ></el-rate>
                  </div>
                  <div class="df-ai-c m-t-5">
                    <img src="@/assets/images/product/indeed_icon.png" />
                    <span class="m-l-20 m-r-10">品质</span>
                    <span>{{ enterpriseinfo.enterpriseScore }}</span>
                  </div>
                  <div class="df-ai-c m-t-5">
                    <img src="@/assets/images/product/depth_icon.png" />
                    <span class="m-l-20 m-r-10">粉丝</span>
                    <span>{{ enterpriseinfo.enterpriseFansCount }}</span>
                  </div>
                  <div slot="reference">
                    {{ enterpriseinfo.enterpriseShopName }}
                    <i
                      class="el-icon-arrow-down m-l-20 enterpriseShopNamedown"
                    ></i>
                  </div>
                </el-popover>
                <enterpriseClaim :enterpriseId="enterpriseinfo.id" />
              </div>
              <div class="m-t-20">
                <enterprise-grade :enterprise="enterpriseinfo" />
              </div>
            </div>
            <div class="">
              <div class="df-ai-c f14 m-b-30 alignRight">
                <span class="df-ai-c pointer m-l-30" v-if="0">
                  <img class="m-r-5" src="@/assets/icon/img_f_icon.png" />
                  店铺海报
                </span>
                <span @click="decoderScene" class="df-ai-c pointer m-l-30">
                  <img class="m-r-5" src="@/assets/icon/share_f_icon.png" />
                  分享
                </span>
                <span
                  class="df-ai-c pointer m-l-30"
                  @click="saveCollectUser(enterpriseinfo)"
                >
                  <!-- <img class="m-r-5" src="@/assets/icon/attention_f_icon.png"> -->
                  <i
                    :class="
                      isCollect ? 'el-icon-star-on f20' : 'el-icon-star-off f16'
                    "
                    class="m-r-5"
                  ></i>
                  {{ isCollect ? "已" : "" }}收藏
                </span>
                <span class="df-ai-c pointer m-l-30" @click="openMessage">
                  <img class="m-r-5" src="@/assets/icon/service_f_icon.png" />
                  在线客服
                </span>
                <!--搜索 组件-->
              </div>
              <search-product2 @ourShop="ourShop"></search-product2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="topTabsBody">
      <div
        :style="{ width: this.$oucy.pageWidth + 'px' }"
        class="df-jc-s-b topTabs"
      >
        <div
          class="topTab df-ai-c"
          :class="select == 4 ? 'select' : ''"
          @click="$oucy.go('/ShopVip?enterpriseId=' + enterpriseinfo.id)"
        >
          <img
            src="@/assets/images/product/viphome.png"
            style="width: 32px; height: auto"
          />
          店铺首页
        </div>
        <div class="topTab df-ai-c">
          <!--                     <img src="@/assets/images/product/Certification.png">认证档案-->
          <el-popover placement="bottom" width="700" trigger="hover">
            <div
              class="Certifications"
              v-if="enterpriseinfo.enterpriseCertificationFile"
            >
              <div class="Certification-top">
                <div class="box">
                  <div class="box-top">
                    {{
                      enterpriseinfo.enterpriseCertificationFile
                        .fileCountEmploeey
                    }}
                  </div>
                  <div class="box-bottom">员工人数</div>
                </div>
                <div class="box">
                  <div class="box-top">
                    {{
                      enterpriseinfo.enterpriseCertificationFile.fileCountPatent
                    }}
                  </div>
                  <div class="box-bottom">专利数量</div>
                </div>
                <div class="box">
                  <div class="box-top">
                    {{ enterpriseinfo.enterpriseCertificationFile.fileYield }}
                  </div>
                  <div class="box-bottom">年产量</div>
                </div>
                <div class="box">
                  <div class="box-top">
                    {{
                      enterpriseinfo.enterpriseCertificationFile
                        .fileProductPeriod
                    }}
                  </div>
                  <div class="box-bottom">生产周期</div>
                </div>
                <div class="box">
                  <div class="box-top">
                    {{
                      enterpriseinfo.enterpriseCertificationFile.fileShowArea
                    }}
                  </div>
                  <div class="box-bottom">展厅面积</div>
                </div>
                <div class="box">
                  <div class="box-top">
                    {{
                      enterpriseinfo.enterpriseCertificationFile.fileFactoryArea
                    }}
                  </div>
                  <div class="box-bottom">工厂面积</div>
                </div>
              </div>
              <div class="m-t-15">
                <span class="tabTitle">生产类型</span>
                <span
                  class="tag"
                  v-for="(v, i) of enterpriseinfo.enterpriseCertificationFile
                    .fileProductTypes"
                  :key="i"
                >
                  {{ v }}
                </span>
              </div>
              <div class="m-t-10">
                <span class="tabTitle">主营范围</span>
                <span
                  class="tag"
                  v-for="(v, i) of enterpriseinfo.enterpriseCertificationFile
                    .fileMainScope"
                  :key="i"
                >
                  {{ v }}
                </span>
              </div>
              <div class="m-t-10">
                <span class="tabTitle">发 货 地</span>
                <span class="m-l-10 f12 c-1">
                  {{ enterpriseinfo.enterpriseCertificationFile.fileDispatch }}
                </span>
              </div>
            </div>
            <div v-else class="text-center">暂无认证档案</div>
            <a
              slot="reference"
              style="width: 256px"
              class="df-ai-c df-jc-c"
              :class="select == 0 ? 'select' : ''"
            >
              <img src="@/assets/images/product/Certification.png" />
              <span style="">认证档案</span>
            </a>
          </el-popover>
        </div>
        <div
          class="topTab df-ai-c"
          :class="select == 1 ? 'select' : ''"
          title="暂未开放"
        >
          <img src="@/assets/images/product/photo.png" />
          企业相册
        </div>
        <div
          class="topTab df-ai-c"
          :class="select == 2 ? 'select' : ''"
          @click="$oucy.go('/ShopVideo?enterpriseId=' + enterpriseinfo.id)"
        >
          <img src="@/assets/images/product/video.png" />
          企业视频
        </div>
        <div
          class="topTab df-ai-c"
          :class="select == 3 ? 'select' : ''"
          title="暂未开放"
          v-if="0"
        >
          <img src="@/assets/images/product/VR.png" />
          全景VR
        </div>
        <div
          class="topTab df-ai-c"
          :class="select == 4 ? 'select' : ''"
          title="暂未开放"
        >
          <img src="@/assets/images/product/plant.png" />
          联系工厂
        </div>
      </div>
    </div>
    <div class="image__preview" v-if="show" @click="show = false">
      <el-image
        style="height: 80%; width: auto"
        :src="url"
        @click.stop="download()"
      ></el-image>
    </div>
    <!-- 分享海报 -->
    <sharePoster ref="sharePoster"></sharePoster>
  </div>
</template>
<script>
  import oucy from "@/util/oucyUtil";
  import { localSet, localGet, localDel } from "@/store/store";
  import { collectuser, scence, poster } from "@/service/index.js";
  export default {
    name: "vipTop",
    props: {
      enterpriseinfo: {
        type: Object,
        default: function () {
          return {};
        },
      },
      select: {
        type: [String, Number],
        default: null,
      },
    },

    data() {
      return {
        pageWidth: oucy.pageWidth,
        bannerHeight: 1000,
        bannerImgHeight: 560,
        bannerImgWidth: 1920,
        screenWidth: 0,
        setSizeCount: 0,
        setSizeTimId: null,
        isCollect: null,
        url: null,
        show: null,
      };
    },
    mounted() {
      let loginUser = localGet(this.$oucy.userInfoKey);
      if (
        loginUser &&
        loginUser.id &&
        this.enterpriseinfo &&
        this.enterpriseinfo.id
      ) {
        this.getIsCollect();
      } else {
        // 可能是在这个页面登陆的
        setTimeout(() => {
          let loginUser = localGet(this.$oucy.userInfoKey);
          if (
            loginUser &&
            loginUser.id &&
            this.enterpriseinfo &&
            this.enterpriseinfo.id
          ) {
            this.getIsCollect();
          }
        }, 5000);
      }
    },

    methods: {
      ourShop(v) {
        this.$emit("ourShop", v);
      },
      openMessage(v) {
        this.$emit("openMessage", v);
      },
      // 是否收藏
      getIsCollect() {
        collectuser
          .isCollect({ collectId: this.enterpriseinfo.id })
          .then((res) => {
            this.isCollect = res;
            // this.$message('操作成功')
          });
      },
      /**
       * 收藏企业
       * @param  {[type]} v [description]
       * @return {[type]}   [description]
       */
      saveCollectUser(v) {
        collectuser
          .saveCollectUser({ collectType: 1, collectId: v.id })
          .then((res) => {
            this.isCollect = !this.isCollect;
            this.$message("操作成功");
          });
      },
      decoderScene() {
        let obj = {
          objectId: this.enterpriseinfo.id,
          playbillCategory: 9,
          redirectUrlScene: { id: this.enterpriseinfo.id },
        };
        this.$refs.sharePoster.show(obj);
      },
      download(url = this.url) {
        let a = document.createElement("a");
        a.download = `${new Date().toLocaleString()}海报`;
        a.setAttribute("href", url);
        a.click();
      },
    },
  };
</script>
<style scoped lang="less">
  .image__preview {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
  }

  .logo {
    height: 58px;
    width: 163px;
  }

  .input-container {
    width: 500px;
    /*margin: 0 auto;*/
  }

  .topCenter {
    background: url("../../../assets/images/product/vipTopBG.png");
    background-size: 100% 100%;
  }

  .topCenterBody {
    height: 261px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: left;
  }

  .merchantLogo {
    width: 128px;
    height: 128px;
    border-radius: 3px;
  }

  .merchantText {
    flex: 1;
  }

  .topBottom {
    width: 100%;
    background: #fff;
  }

  .breadcrumbBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .z-skus {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .z-sku {
    width: 30%;
    padding-right: 10px;
    box-sizing: border-box;
    margin-right: 10px;
  }

  .lisColor {
    box-sizing: border-box;
    border: 3px solid #2090ff;
  }

  .notlisColor {
    box-sizing: border-box;
    border: 3px solid #fff;
  }

  /*头部*/

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .product.topBottom .el-menu.el-menu--horizontal {
    border: none;
    /*font-weight: bold;*/
    font-size: 16px;
    color: #333;
  }

  .product .el-menu-item {
    font-size: 16px;
  }

  .product .el-menu--horizontal > .el-menu-item {
    color: #333;
  }

  .product .el-menu--horizontal > .el-submenu .el-submenu__title {
    font-size: 16px;
    color: #333;
  }

  .nav img {
    margin-right: 8px;
  }

  .nav:hover .icon1 {
    display: none;
  }

  .nav:hover .icon2 {
    display: inline-block;
  }

  .icon2 {
    display: none;
  }

  .bold_span {
    font-size: 16px;
    // font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 0px;*/
    /*display: inline-block;*/
    /*padding: 20px;*/
  }

  .top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
  }

  .auth {
    display: flex;
    margin-bottom: 30px;
  }

  .auth > span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
  }

  .auth .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    // font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
  }

  .auth .el-button--text.select {
    color: #409eff;
  }

  .el-button--text:hover {
    /*color: rgb(32,144,255) !important;*/
  }

  .el-button--mini {
    border: none;
  }

  .el-button--mini:hover {
    /*background-color: rgb(32,144,255) !important;*/
  }

  .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  .middle_middle {
    height: 52px;
    background-color: #ffffff;
    margin: 10px 0 10px 10px;
  }

  .middle_middle span {
    font-size: 14px;
    // font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
  }

  .middle_middle .el-input {
    width: 97px;
    margin-right: 10px;
  }

  .list .el-table__row > td {
    /*去除表格线*/
    border: none;
  }

  .list .el-table th.is-leaf {
    /*去除上边框*/
    border: none;
  }

  .list .el-table::before {
    /*去除下边框*/
    height: 0;
  }

  .wangge {
    float: right;
    margin-right: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
  }

  .middle ul li:nth-child(4n) {
    margin-right: 0px;
  }

  .middle ul {
    text-align: left;
  }

  .middle ul li {
    width: 170px;
    height: 340px;
    background-color: #ffffff;
    // padding: 27px;
    /*float: left;*/
    display: inline-block;
    list-style: none;
    cursor: pointer;
  }

  .title {
    font-size: 14px;
    // font-family: Microsoft YaHei;
    font-weight: bold;
    color: #111111;
    line-height: 1.2;
  }

  .brand_name span {
    font-size: 12px;
    // font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    // float: left;
    margin-right: 5px;
  }

  .price {
    font-size: 20px;
    // font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e92121;
    line-height: 50px;
  }

  .stock {
    font-size: 12px;
    // font-family: Microsoft YaHei;
    font-weight: 400;
    color: #aaaaaa;
    line-height: 50px;
  }

  .add .el-button--text {
    // box-sizing:border-box;
    width: 100%;
    height: 29px;
    font-size: 14px;
    // font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
    border: 1px solid #2090ff;
    border-radius: 2px;
    margin-top: 15px;
    line-height: 0px;
  }

  .pagination {
    margin-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 40px;
    // line-height: 150px;
  }

  .liebiao {
    min-height: 500px;
  }

  .liebiao ul li {
    width: 850px;
    height: 100px;
    background-color: #ffffff;
    list-style: none;
    margin-left: -20px;
  }

  table {
    text-align: left;
    // margin-top: 15px;
  }

  .liebiao .el-button--text {
    width: 90px;
    height: 29px;
    font-size: 14px;
    // font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
    border: 1px solid #2090ff;
    border-radius: 2px;
    line-height: 0px;
  }

  .grid {
    /*min-height: 500px;*/
  }

  .grid ul {
    // margin-left: -25px;
  }

  .el-page-header__title {
    background-color: red;
  }

  .wangge {
    float: right;
    margin: 15px 30px 0 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .wangge span {
    font-size: 14px;
    margin-left: 8px;
    color: #666666;
  }

  .brand_name {
    text-align: center;
    font-size: 12px;
    // font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    /*float: left;*/
    height: 15px;
  }

  .com_card {
    background-image: url("../../../assets/goods/background.png");
    width: 210px;
    height: 150px;
    /*margin-top: 65px;*/
  }

  .com_card p {
    line-height: 40px;
    font-size: 12px;
    // font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    text-align: center;
    margin: 0 auto;
  }

  .com_card img {
    padding: 10px;
    margin-top: 10px;
  }

  /*/deep/ .btn-next{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .btn-prev{width: 128px;height: 46px!important;background: #FFFFFF!important;}
/deep/ .el-pager{position: relative;top: 9px;}
/deep/ .number{background: #FFFFFF!important;}
/deep/ .active{background:#2090ff!important ;}*/
  .classifyIcon {
    width: 15px;
    height: 15px;
  }

  .spuImgs {
    width: 219px;
    height: 219px;
    background-color: #f1f1f1;
  }

  .spuImgs2 {
    width: 96px;
    height: 96px;
    background-color: #f1f1f1;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .el-menu-item,
  .product /deep/ .el-submenu__title {
    font-size: 16px;
    color: #333;
  }

  .carousel img {
    /*设置图片宽度和浏览器宽度一致*/
    width: 100%;
    height: inherit;
  }

  .headline {
    text-align: center;
    margin-top: 10px;
    padding-top: 40px;
    font-size: 24px;
    font-weight: bold;
    color: #323a4a;
    color: #323a4a;
  }

  .headlinetext {
    color: #777e8c;
    font-size: 12px;
  }

  .headline_line {
    display: inline-block;
    width: 24px;
    height: 6px;
    background: #323a4a;
    border-radius: 2px;
    position: relative;
    top: -10px;
  }

  .centerNav {
    padding: 15px 0;
  }

  .nav {
    cursor: pointer;
    flex: 1;
    text-align: center;

    span {
      margin: 0 auto;
      width: 30px;
      height: 6px;
      background: #323a4a;
      border-radius: 2px;
      position: relative;
      display: none;
      top: 16px;
    }

    &.selsct {
      span {
        display: block;
      }
    }
  }

  .right_top {
    display: flex;
    background: #fff;
    padding: 20px;

    .inputprice {
      width: 76px;
    }
  }

  .store_kind .el-button.selsct {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090ff;
  }

  .line-h {
    display: inline-block;
    width: 5px;
    height: 16px;
    background: #2090ff;
    border-radius: 1px;
    position: relative;
    top: 2px;
  }

  .alignRight {
    // display: flex;
    flex-direction: row-reverse;
  }

  .topTabsBody {
    background: #323a4a;
    color: #b4b9c5;
  }

  .topTabs {
    margin-left: auto;
    text-align: center;
    margin-right: auto;
    justify-content: center;
    height: 40px;
  }

  .topTab {
    text-align: center;
    justify-content: center;
    flex: 1;

    img {
      margin-right: 20px;
    }

    &:hover {
      background: #384154;
      color: #fff;
    }
    &.select {
      background: #384154;
      border-bottom: 6px solid #2090ff;
    }
  }

  .hotSaleProducts {
    justify-content: space-between;
    // background:#030;
    width: 1280px;
  }

  .hotSaleProduct {
    width: 633px;
    position: relative;
    margin-bottom: 10px;
  }

  .hotSaleProduct + .hotSaleProduct {
    margin-left: 20px;
  }

  .hotSaleProduct_img {
    width: 100%;
  }

  .hotSaleProducts_btm {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    padding: 6px 10px;
    box-sizing: border-box;
  }

  .navTypeSpu_item:nth-child(4n) {
    margin-right: 0;
  }

  .enterpriseShopNamedown {
    background: #363e4f;
    padding: 4px;
  }

  // 认证档案
  .Certifications {
    .Certification-top {
      display: flex;

      .box {
        text-align: center;
        flex: 1;
        background: #f6f7f8;
        border-radius: 2px;
        margin: 5px;
        padding: 20px;

        .box-top {
          font-size: 14px;
          // font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2090ff;
        }

        .box-bottom {
          font-size: 14px;
          // font-family: Microsoft YaHei;
          font-weight: 400;
          color: #555c68;
          margin-top: 25px;
        }
      }
    }

    .tabTitle {
      font-size: 12px;
      font-family: SimSun;
      font-weight: 400;
      color: #aaaaaa;
    }

    .tag {
      font-size: 12px;
      font-family: SimSun;
      font-weight: 400;
      color: #555c68;
      background: #f6f7f8;
      border-radius: 1px;
      display: inline-block;
      padding: 2px 6px;
      margin-left: 10px;
    }
  }

  .navTypeSpu_item /deep/ .spuImgs {
    width: 233px;
    height: 233px;
  }

  .list4 /deep/ .spuImgs {
    width: 305px;
    height: 305px;
  }

  .list5 /deep/ .spuImgs {
    width: 240px;
    height: 240px;
  }

  .itemCover {
    width: 100%;
    height: 215px;
    position: relative;
    overflow: hidden;

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      display: none;
      // transform: translate(-50%, -50%);
    }

    .el-icon-video-play {
      font-size: 50px;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      display: none;
    }

    &:hover .bg {
      display: inline-block;
    }

    &:hover .el-icon-video-play {
      display: inline-block;
    }
  }

  .boxs {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    padding: 24px;
  }

  .box {
    width: 25%;
    box-sizing: border-box;
    padding: 0 10px 20px;
  }

  .item {
    background: #f6f6f6;
    font-size: 14px;
    // font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;

    .itemInfo {
      box-sizing: border-box;
      height: 85px;
      padding: 30px 10px 40px;
    }
  }
  .videoAlbumName {
    font-size: 14px;
    // font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    padding: 15px 0;
    border-bottom: solid 4px #f8f8f8;
  }
</style>
